<template>
    <v-data-table :headers="headers" :items="paymentMethods" sort-by="code" class="elevation-1">
        <template v-slot:top>
            <v-toolbar flat>
                <v-toolbar-title v-t="'paymentMethods'"></v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <v-dialog v-model="dialog" max-width="600px">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                            {{ $t('newParam', {param:$t('paymentMethod', {r:''})}) }}
                        </v-btn>
                    </template>
                    <v-card>
                        <v-card-title>
                            <span class="text-h5">{{ $t('paymentMethod', {r:''}) }}</span>
                        </v-card-title>

                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col cols="12" sm="6" md="3">
                                        <v-text-field v-model="editedItem.code" :label="$t('code')"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="9">
                                        <v-text-field v-model="editedItem.description" :label="$t('description')"></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="close" v-t="'cancel'"></v-btn>
                            <v-btn color="blue darken-1" text @click="save" v-t="'confirm'"></v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">
                mdi-pencil
            </v-icon>
        </template>
        <template v-slot:no-data>
            <v-btn color="primary" @click="initialize" v-t="'reset'"></v-btn>
        </template>
    </v-data-table>
</template>
<script>
import {call, get, sync} from "vuex-pathify";
export default {
    name: "PaymentMethods",
    data: () => ({
        dialog: false,
        editedItem: {},
    }),
    computed: {
        paymentMethods: sync("orders/paymentMethods"),
        headers(){
            return [
                { text: this.$t('code'), value: 'code' },
                { text: this.$t('description'), value: 'description' },
                { text: this.$t('actions'), value: 'actions', sortable: false },
            ]
        }
    },
    watch: {
        dialog(val) {
            val || this.close()
        },
    },
    created() {
        this.initialize()
    },
    methods: {
        initialize() {
            this.initPaymentMethodCollection();
        },
        editItem(item) {
            this.editedItem = item;
            this.dialog = true
        },
        close() {
            this.editedItem = {};
            this.dialog = false
        },
        save() {
            let promise = !this.editedItem?.id ? this.apiPaymentMethodPost : this.apiPaymentMethodIdPut;
            promise(this.editedItem)
            .finally(() => {
                this.close()
            })
        },
        ...call("orders/*")
    },
}
</script>